<template>
  <Documents :key="currentFolder"></Documents>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import Documents from '@/components/Documents.vue'

export default {
  name: 'List',
  data: () => ({
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
  },
  computed: {
    currentFolder () {
      const routeParams = this.$route.params
      const currentRootFolderName = routeParams.rootFolderName

      if (!currentRootFolderName) {
        return null
      }

      let key = currentRootFolderName
      const folder = this.$route.query.folder

      if (folder) {
        key += folder
      }

      return key
    }
  },
  components: {
    Documents
  }
}
</script>
