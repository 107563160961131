<template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteSelectedDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="successMessage">
              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
              >
                {{ successMessage }}
              </v-alert>
            </p>

            <span v-if="documentIds.length || folderIds.length" v-html="$t('message.confirm_multiple_deletion', { count: documentIds.length + folderIds.length })"></span>
            <br>
            <br>
            <ul>
              <li v-for="(currentFolder, folderIndex) in foldersToDelete" :key="'f' + folderIndex">
                <span v-html="$t('message.folder_and_content', { folderName: currentFolder.name, nbChildren: currentFolder.nbChildren })"></span>
              </li>
              <li v-for="(currentDocument, documentIndex) in documentsToDelete" :key="'d' + documentIndex">
                <span v-html="$t('message.document_name_extension', { documentName: currentDocument.name, documentExtension: currentDocument.extension })"></span>
              </li>
            </ul>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDeleteSelectedDialog()"
          >
            {{ $t('message.cancel') }}
          </v-btn>

          <button type="button" :disabled="loading" v-on:click="deleteSelected()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.validate') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'DeleteSelectedDialog',
  data: () => ({
    successMessage: null,
    loading: false,
    errors: []
  }),
  props: {
    documentsToDelete: Array,
    documentIds: Array,
    foldersToDelete: Array,
    folderIds: Array,
    deleteSelectedDialogState: Boolean
  },
  methods: {
    deleteSelected () {
      if (this.documentIds.length > 0) {
        const documentIds = this.documentIds.join(',')

        this.deletedSelectedDocuments(documentIds)
      }

      if (this.folderIds.length > 0) {
        const folderIds = this.folderIds.join(',')

        this.deletedSelectedFolders(folderIds)
      }
    },
    deletedSelectedDocuments (documentIds) {
      this.loading = true

      const data = {
        ids: documentIds
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'document',
            _method: 'DELETE'
          }
        })
        .then(response => {
          if (response.status === 202) {
            const deletedDocumentIds = documentIds.split(',')

            for (let i = 0; i < deletedDocumentIds.length; i++) {
              const currentDocumentId = deletedDocumentIds[i]

              this.$store.commit('deleteDocument', { id: currentDocumentId })
            }

            this.successMessage = this.$t('message.selection_has_been_deleted')
            this.errors = []
            this.closeDeleteSelectedDialog()

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.selection_deletion_error')
            })
          }
        })
    },
    deletedSelectedFolders (folderIds) {
      this.loading = true

      const data = {
        ids: folderIds
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/folders', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'document',
            _method: 'DELETE'
          }
        })
        .then(response => {
          if (response.status === 202) {
            const deletedFolderIds = folderIds.split(',')

            for (let i = 0; i < deletedFolderIds.length; i++) {
              const currentFolderId = deletedFolderIds[i]

              this.$store.commit('deleteFolder', { id: currentFolderId })
            }

            this.successMessage = this.$t('message.selection_has_been_deleted')
            this.errors = []
            this.closeDeleteSelectedDialog()
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false

          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.selection_deletion_error')
            })
          }
        })
    },
    closeDeleteSelectedDialog () {
      this.$emit('closeDeleteSelectedDialog', false)
    }
  }
}
</script>
