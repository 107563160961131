<template>
  <div id="content"
       @mousemove="onMouseMove($event)"
       @dragover.prevent.stop="onDragover($event)"
       @dragenter.prevent.stop="onDragenter($event)"
       @dragleave.prevent.stop="onDragleave($event)"
       @dragend.prevent.stop="onDragend($event)"
       @drop.prevent.stop="onDrop($event)">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>

    <div id="document-wrapper" class="wrapper">
      <div id="sub-wrapper">
        <v-alert
          v-if="showNewAccountMessage"
          icon="mdi-information-outline"
          prominent
          text
          type="info"
        >
          {{ $t('message.safe_creation') }}
        </v-alert>

        <header>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" v-if="currentRootFolder">
              <li
                v-for="(element, index) in breadCrumb"
                :key="index"
                class="breadcrumb-item"
                :class="{
                  active: index === Object.keys(breadCrumb).length - 1,
                  draggable: draggable === element.id && index !== Object.keys(breadCrumb).length - 1
                }"
                @mouseover="onMouseOver($event, element)"
                @mouseup.left.stop="onMouseUpFolder($event, element)"
              >
                <span v-on:click="openFolder(element)">{{ showName(element) }}</span>
              </li>
            </ol>
            <div class="subtitle-bread" v-if="currentRootFolder.name === 'Documents FIDUCIAL'">
              <span>{{ $t('message.publication_rule') }}</span>
            </div>
          </nav>
          <div class="btn-mass-actions">
            <label v-if="hasEditableContent" for="file-upload" class="btn btn-sm btn-success" :title="$t('message.file_deposit')">
              <i class="fas fa-file-upload"></i><span>{{ $t('message.deposit') }}</span>
              <input type="file" id="file-upload" @change="chooseFiles($event)" multiple hidden>
            </label>
            <button v-if="hasEditableContent" class="btn btn-sm btn-primary" :title="$t('message.new_folder')" @click="openCreateFolderDialog()"><i class="fas fa-folder-plus"></i><span>{{ $t('message.new_folder') }}</span></button>
            <button class="btn btn-sm btn-primary" :title="$t('message.download_selected')" @click="downloadSelected()" :disabled="checkboxCount <= 0"><i class="fas fa-download"></i><span>{{ $t('message.download') }}</span></button>
            <button v-if="hasDeletableContent" class="btn btn-sm btn-danger" :title="$t('message.delete_selected')" @click="openDeleteSelectedDialog()" :disabled="checkboxCount <= 0"><i class="fas fa-trash"></i><span>{{ $t('message.delete') }}</span></button>
          </div>
        </header>

        <div v-if="resolvePercentUsage() >= 90 && (hasIgnoredUsageMsg === 'false' || hasIgnoredUsageMsg === false)" role="alert" class="alert-disk-usage">
          <div id="bannerInfo">
            <span class="font-weight-bold">{{ $t('message.used_storage', { percentage: resolvePercentUsage() }) }}</span>
            <span v-if="user.rcu" class="font-weight-normal">{{ $t('message.need_more_space') }}</span>
          </div>
          <div class="actions-container">
            <button>
              <span :class="{ 'upgrade-action': !user.rcu }" class="button-action" @click="ignoreUsageMsg()">{{ $t('message.ignore') }}</span>
            </button>
            <a v-if="user.rcu" :href='getUpgradeStorageUrl()' target="_blank" draggable="false" class="button-action upgrade-action">
              {{ $t('message.upgrade') }}
            </a>
          </div>
        </div>

        <v-alert
          text
          dense
          type="error"
          v-if="mkError !== null"
        >
          {{ mkError }}
        </v-alert>

        <div id="overlay" v-if="dragged.length && hasEditableContent" @mouseup="onMouseUpOverlay()"></div>

        <button id="tail" class="btn btn-sm btn-primary" v-if="dragged.length && hasEditableContent">
          <i class="el-type fas fa-arrow-alt-circle-right"></i> <span>{{ $t('message.move_selection') }}</span>
        </button>

        <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" >
          <thead>
            <tr>
              <th>
                <v-checkbox dense v-model="checkbox" v-on:change="updateCheckboxes()" class="document-checkbox"></v-checkbox>
              </th>
              <th>{{ $t('message.name') }}</th>
              <th class="sm-hidden">{{ $t('message.deposit_date') }}</th>
              <th>{{ $t('message.actions') }}</th>
            </tr>
          </thead>
          <tbody>
          <tr
            v-for="(folder, folderIndex) in folders"
            :key="'f' + folderIndex"
            :class="{ dragged: dragged.includes('f' + folder.id), draggable: draggable === folder.id, folder: true }"
          >
            <td><v-checkbox dense :value="folder.selected" v-model=folder.selected class="document-checkbox" @change="updateCheckboxCount(folder.selected)"></v-checkbox></td>
            <td :data-label="$t('message.name')">
              <div class="folder-container"
                   @click="onMouseClickFolder($event, folder)"
                   @mouseover="onMouseOver($event, folder)"
                   @mousedown.left.prevent="onMouseDown($event, folder)"
                   @mouseup.left.stop="onMouseUpFolder($event, folder)"
              >
                <i class="el-type fas fa-folder"></i><span class="ml-i2 font-weight-bold">{{ folder.name }}</span>
                <span v-if="folder.read === false && hasEditableContent === false" class="unread badge bg-danger">{{ $t('message.new') }}</span>
              </div>
            </td>
            <td :data-label="$t('message.deposit_date')" class="sm-hidden"></td>
            <td class="actions" :data-label="$t('message.actions')">
              <button :title="$t('message.download')" v-on:click="downloadFolder(folder)"><i class="fas fa-download"></i></button>
              <button v-if="hasEditableContent" :title="$t('message.rename')" v-on:click="openUpdateFolderDialog(folder)"><i class="fas fa-pen"></i></button>
              <button v-if="hasDeletableContent" :title="$t('message.delete')" v-on:click="openDeleteFolderDialog(folder)"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
          <tr
            v-for="(document, documentIndex) in documents"
            :key="'d' + documentIndex"
            :class="{ dragged: dragged.includes('d' + document.id) }"
            @mousedown.left.prevent="onMouseDown($event, document)"
            @mouseup.left.stop="onMouseUpDocument($event, document)"
          >
            <td><v-checkbox dense :value="document.selected" v-model=document.selected class="document-checkbox" @change="updateCheckboxCount(document.selected)"></v-checkbox></td>
            <td :data-label="$t('message.name')">
              <i :class='"el-type mdi " + resolveIcon(document)'></i><span class="ml-i1 font-weight-bold">{{ document.name }}.{{ document.extension }}</span>
              <span v-if="document.read === false && hasEditableContent === false" class="unread badge bg-danger">{{ $t('message.new') }}</span>
            </td>
            <td :data-label="$t('message.deposit_date')" class="sm-hidden">
              {{ document.createdAt ? formatDate(document.createdAt) : '-' }}
            </td>
            <td class="actions" :data-label="$t('message.actions') + ':'">
              <button :title="$t('message.download')" v-on:click="downloadDocument(document)"><i class="fas fa-download"></i></button>
              <button v-if="isPreviewable(document)" :title="$t('message.view')" v-on:click="openPreviewDocumentDialog(document)"><i class="fas fa-eye"></i></button>
              <button :title="$t('message.information')" v-on:click="showDocumentDetails(document)"><i class="fas fa-info-circle"></i></button>
              <button v-if="hasEditableContent" :title="$t('message.rename')" v-on:click="openUpdateDocumentDialog(document)"><i class="fas fa-pen"></i></button>
              <button v-if="hasDeletableContent" :title="$t('message.delete')" v-on:click="openDeleteDocumentDialog(document)"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="document-details" :class="'sidenav' + (user.rcu ? '-clients' : '')">
      <div id="volet-header">
        <div class="float-left header-document-name">
          {{ currentDocument ? truncate(currentDocument) : '-' }}
        </div>
        <div class="float-right">
          <button class="close-doc-details" data-toggle="tooltip" data-placement="top" :title="$t('message.close')" @click="hideDocumentDetails()"><i class="fas fa-times"></i></button>
        </div>
        <div class="clear-both"></div>
        <div class="header-details" @click="showDocumentDetails(currentDocument)">{{ $t('message.details') }}</div>
        <div class="header-history" @click="showDocumentHistory(currentDocument)">{{ $t('message.activity') }}</div>
        <div class="clear-both"></div>
      </div>
      <div v-if="documentDetailsStatus" id="volet-content">
        <h3>
          {{ $t('message.system_properties') }}
        </h3>
        <div v-if="documentDetailsLoader" class="dot-flashing"></div>
        <table v-if="!documentDetailsLoader">
          <tbody>
          <tr>
            <td class="type-label">{{ $t('message.type') }}</td>
            <td class="type-value">{{ currentDocument ? currentDocument.extension : '-' }}</td>
          </tr>
          <tr>
            <td class="size-label">{{ $t('message.size') }}</td>
            <td class="size-value">{{ currentDocument ? resolveSize(currentDocument.size) : '-' }}</td>
          </tr>
          <tr>
            <td class="created-at-label">{{ $t('message.creation_date') }}</td>
            <td class="created-at-value">{{ currentDocument ? formatDate(currentDocument.createdAt) : '-' }}</td>
          </tr>
          <tr>
            <td class="check-label">{{ $t('message.file_integrity') }}</td>
            <td class="check-value">
              <v-chip
                color="teal"
                text-color="white"
                v-if="isCurrentDocumentIntact"
              >
                <v-avatar left>
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>
                {{ $t('message.verified') }}
              </v-chip>
              <v-chip
                color="red"
                text-color="white"
                v-if="!isCurrentDocumentIntact"
              >
                <v-avatar left>
                  <v-icon>mdi-close-circle</v-icon>
                </v-avatar>
                {{ $t('message.corrupted_file') }}
              </v-chip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="documentHistoryStatus" id="volet-history">
        <div v-if="documentHistoryLoader" class="dot-flashing"></div>
        <div v-if="!documentHistoryLoader">
          <div v-for="(log, logIndex) in currentLogs"
               :key="'l' + logIndex"
               class="log-container mb-5"
          >
            <h5 class="log-date">{{ formatDate(log.createdAt) }}</h5>
            <p class="log-content">{{ log.action }} <br> par {{ log.user.firstName + ' ' + log.user.lastName }} <br> ({{ log.ip + ' - ' + log.browser }})</p>
          </div>
        </div>
      </div>
    </div>

    <div id="watermark" v-if="hasEditableContent && documents.length === 0 && loading === false">
      <span class="font-weight-bold">{{ $t('message.drop_files_here') }}</span>
      <br>
      <span>{{ $t('message.or_use_upload_button') }}</span>
      <img src="@/assets/images/watermark.png" draggable="false">
    </div>

    <CreateFolderDialog
      :key="Date.now()"
      :create-folder-dialog-state="createFolderDialogState"
      @closeCreateFolderDialog="closeCreateFolderDialog($event)"
    ></CreateFolderDialog>

    <UpdateFolderDialog
      :key="Date.now() + 1"
      :update-folder-dialog-state="updateFolderDialogState"
      :folder="folderToUpdate"
      @closeUpdateFolderDialog="closeUpdateFolderDialog($event)"
    ></UpdateFolderDialog>

    <DeleteFolderDialog
      :key="Date.now() + 2"
      :delete-folder-dialog-state="deleteFolderDialogState"
      :folder="folderToDelete"
      :source="'document'"
      @closeDeleteFolderDialog="closeDeleteFolderDialog($event)"
    ></DeleteFolderDialog>

    <UpdateDocumentDialog
      :key="Date.now() + 3"
      :update-document-dialog-state="updateDocumentDialogState"
      :document="documentToUpdate"
      @closeUpdateDocumentDialog="closeUpdateDocumentDialog($event)"
    ></UpdateDocumentDialog>

    <DeleteDocumentDialog
      :key="Date.now() + 4"
      :delete-document-dialog-state="deleteDocumentDialogState"
      :document="documentToDelete"
      @closeDeleteDocumentDialog="closeDeleteDocumentDialog($event)"
    ></DeleteDocumentDialog>

    <PreviewPdfDialog
      v-if="documentToPreview && destroyPreviewPdfComponent && isPdf(documentToPreview)"
      :key="654687684"
      :preview-pdf-dialog-state="previewPdfDialogState"
      :document="documentToPreview"
      @closePreviewPdfDialog="closePreviewPdfDialog($event)"
    ></PreviewPdfDialog>

    <PreviewImgDialog
      v-if="documentToPreview && destroyPreviewImgComponent && isImg(documentToPreview)"
      :key="12314657895"
      :preview-img-dialog-state="previewImgDialogState"
      :document="documentToPreview"
      @closePreviewImgDialog="closePreviewImgDialog($event)"
    ></PreviewImgDialog>

    <PreviewXlsDialog
      v-if="documentToPreview && destroyPreviewXlsComponent && isXls(documentToPreview)"
      :key="963258415"
      :preview-xls-dialog-state="previewXlsDialogState"
      :document="documentToPreview"
      @closePreviewXlsDialog="closePreviewXlsDialog($event)"
    ></PreviewXlsDialog>

    <PreviewCsvDialog
      v-if="documentToPreview && destroyPreviewCsvComponent && isCsv(documentToPreview)"
      :key="8855523647"
      :preview-csv-dialog-state="previewCsvDialogState"
      :document="documentToPreview"
      @closePreviewCsvDialog="closePreviewCsvDialog($event)"
    ></PreviewCsvDialog>

    <PreviewTxtDialog
      v-if="documentToPreview && destroyPreviewTxtComponent && isTxt(documentToPreview)"
      :key="99945523"
      :preview-txt-dialog-state="previewTxtDialogState"
      :document="documentToPreview"
      @closePreviewTxtDialog="closePreviewTxtDialog($event)"
    ></PreviewTxtDialog>

    <DeleteSelectedDialog
      :key="Date.now() + 6"
      :delete-selected-dialog-state="deleteSelectedDialogState"
      :documents-to-delete="documentsToDelete"
      :document-ids="documentIds"
      :folders-to-delete="foldersToDelete"
      :folder-ids="folderIds"
      @closeDeleteSelectedDialog="closeDeleteSelectedDialog($event)"
    ></DeleteSelectedDialog>

    <EmptySelectionDialog
      :key="Date.now() + 7"
      :empty-selection-dialog-state="emptySelectionDialogState"
      @closeEmptySelectionDialog="closeEmptySelectionDialog($event)"
    ></EmptySelectionDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import CreateFolderDialog from './Dialog/Documents/FolderDialog/CreateFolderDialog'
import UpdateFolderDialog from './Dialog/Documents/FolderDialog/UpdateFolderDialog'
import DeleteFolderDialog from './Dialog/Documents/FolderDialog/DeleteFolderDialog'
import UpdateDocumentDialog from './Dialog/Documents/DocumentDialog/UpdateDocumentDialog'
import DeleteDocumentDialog from './Dialog/Documents/DocumentDialog/DeleteDocumentDialog'
import PreviewPdfDialog from './Dialog/Documents/DocumentDialog/PreviewPdfDialog'
import PreviewImgDialog from './Dialog/Documents/DocumentDialog/PreviewImgDialog'
import PreviewXlsDialog from './Dialog/Documents/DocumentDialog/PreviewXlsDialog'
import PreviewCsvDialog from './Dialog/Documents/DocumentDialog/PreviewCsvDialog'
import PreviewTxtDialog from './Dialog/Documents/DocumentDialog/PreviewTxtDialog'
import DeleteSelectedDialog from './Dialog/Documents/DeleteSelectedDialog'
import EmptySelectionDialog from './Dialog/Documents/EmptySelectionDialog'
import FileSaver from 'file-saver'
import ProgressBar from 'progressbar.js'
import slugify from 'slugify'
import { Upload } from 'tus-js-client'
import config from '../config'
import Chunk from '../assets/js/chunk'

export default {
  name: 'Documents',
  data: () => ({
    valid: false,
    checkbox: false,
    checkboxCount: 0,
    successMessage: null,
    errors: [],
    loading: false,
    folderName: null,
    dragged: [],
    draggable: null,
    draggedFolders: [],
    draggedDocuments: [],
    timer: null,
    clickCount: 0,
    breadCrumb: [],
    folderToUpdate: null,
    folderToDelete: null,
    createFolderDialogState: false,
    updateFolderDialogState: false,
    deleteFolderDialogState: false,
    documentToUpdate: null,
    documentToDelete: null,
    documentToPreview: null,
    createDocumentDialogState: false,
    updateDocumentDialogState: false,
    deleteDocumentDialogState: false,
    previewPdfDialogState: false,
    previewImgDialogState: false,
    previewXlsDialogState: false,
    previewCsvDialogState: false,
    previewTxtDialogState: false,
    documentsToDelete: [],
    documentIds: [],
    foldersToDelete: [],
    folderIds: [],
    deleteSelectedDialogState: false,
    emptySelectionDialogState: false,
    destroyPreviewPdfComponent: true,
    destroyPreviewImgComponent: true,
    destroyPreviewXlsComponent: true,
    destroyPreviewCsvComponent: true,
    destroyPreviewTxtComponent: true,
    xTarget: null,
    hasEditableContent: false,
    hasDeletableContent: false,
    files: [],
    mkError: null,
    showNewAccountMessage: false,
    nextPage: 1,
    mainFolder: null,
    documentDetailsLoader: false,
    documentDetailsStatus: true,
    documentHistoryLoader: false,
    documentHistoryStatus: false,
    currentDocument: null,
    currentLogs: [],
    isCurrentDocumentIntact: false,
    uploadQueue: [],
    uploadAvailable: true,
    totalNumber: 0,
    start: 0,
    size: 0,
    file: {},
    codes: [400, 404, 415, 422, 500, 501, 503]
  }),
  created () {
    this.$store.commit('setFolders', [])
    this.$store.commit('setDocuments', [])

    window.removeEventListener('scroll', this.handleScroll)

    this.getFolder().then(() => {
      window.addEventListener('scroll', this.handleScroll)
    })

    const currentRootFolder = this.currentRootFolder

    if (!currentRootFolder) {
      return null
    }

    const section = currentRootFolder.section

    if (!section) {
      return null
    }

    this.hasEditableContent = section.editableContent
    this.hasDeletableContent = section.deletableContent

    document.title = config.title + ' - ' + this.$t('message.section_' + section.name)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)

    this.$store.commit('setDocuments', [])
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    folderNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_folder_name')
      ]
    },
    currentRootFolder () {
      const currentRootFolders = this.$store.getters.getCurrentRootFolders
      const routeParams = this.$route.params
      const currentRootFolderName = routeParams.rootFolderName

      if (!currentRootFolderName) {
        return null
      }

      for (let i = 0; i < currentRootFolders.length; i++) {
        const currentRootFolder = currentRootFolders[i]
        const rootFolderName = currentRootFolder.name

        if (slugify(rootFolderName, { lower: true }) === currentRootFolderName) {
          return currentRootFolder
        }
      }

      return null
    },
    currentFolder () {
      const routeParams = this.$route.params
      const currentRootFolderName = routeParams.rootFolderName

      if (!currentRootFolderName) {
        return null
      }

      let key = currentRootFolderName
      const folder = this.$route.query.folder

      if (folder) {
        key += folder
      }

      return key
    },
    folders () {
      return this.$store.getters.getFolders
    },
    documents () {
      return this.$store.getters.getDocuments
    },
    vaultDiskUsage () {
      return this.$store.getters.getVaultDiskUsage
    },
    hasIgnoredUsageMsg () {
      return this.$store.getters.hasIgnoredUsageMsg
    },
    isHandlingDocumentScroll () {
      return this.$store.getters.isHandlingDocumentScroll
    }
  },
  sockets: {
    documentDone (document) {
      const queryFolder = this.$route.query.folder
      const folder = queryFolder || this.currentRootFolder.id
      const folderId = document.folderId

      if (folderId === folder) {
        if (this.documents.length === 0) {
          this.nextPage = this.nextPage + 1
        }

        this.$store.commit('addDocument', document)
      }
    }
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = wrapperOffsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 200

      if (bottomOfWindow && !this.isHandlingDocumentScroll) {
        this.getDocuments(this.mainFolder)
      }
    },
    onDragover () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.add('is-dragover')
    },
    onDragenter () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.add('is-dragover')
    },
    onDragleave () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.remove('is-dragover')
    },
    onDragend () {
      if (!this.hasEditableContent) {
        return
      }

      this.$el.classList.remove('is-dragover')
    },
    onDrop (event) {
      if (!this.hasEditableContent) {
        return
      }

      document.querySelector('#content').classList.remove('is-dragover')

      const files = event.dataTransfer.files

      if (files.length > 0) {
        this.$store.commit('setDocumentsInProgressState', true)

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const currentUploadId = this.generateRandomString()
          const currentUpload = {
            uploadId: currentUploadId,
            file: file,
            document: {
              uploadId: currentUploadId,
              name: file.name,
              status: null,
              progress: 0,
              progressCircle: null,
              errors: null
            }
          }

          this.uploadQueue.push(currentUpload)

          this.$store.commit('addDocumentInProgress', currentUpload.document)
        }

        if (this.uploadAvailable) {
          this.handleUpload(this.uploadQueue[0])
        }
      }
    },
    onMouseMove (event) {
      if (!this.hasEditableContent) {
        return
      }

      if (this.dragged.length) {
        const tail = document.getElementById('tail')

        tail.style.top = event.pageY + 'px'
        tail.style.left = event.pageX + 'px'
      }
    },
    onMouseClickFolder (event, folder) {
      if (!folder) {
        return
      }

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        if (currentFolder.id === folder.id) {
          return
        }
      }

      this.openFolder(folder)
    },
    onMouseDown (event, element) {
      if (!this.hasEditableContent) {
        return
      }

      this.timer = setTimeout(() => {
        element.selected = true
        this.draggedFolders = this.folders.filter(element => element.selected === true)
        this.draggedDocuments = this.documents.filter(element => element.selected === true)
        this.dragged = this.draggedFolders.map(element => 'f' + element.id)
        this.dragged = this.dragged.concat(this.draggedDocuments.map(element => 'd' + element.id))
      }, 150)
    },
    onMouseUpOverlay () {
      if (!this.hasEditableContent) {
        return
      }

      this.dragged = []
      this.draggable = null

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        currentFolder.selected = false
      }

      this.draggedFolders = []

      for (let i = 0; i < this.draggedDocuments.length; i++) {
        const currentDocument = this.draggedDocuments[i]

        currentDocument.selected = false
      }

      this.draggedDocuments = []
    },
    onMouseUpFolder (event, folder) {
      if (!this.hasEditableContent) {
        return
      }

      if ((this.draggedFolders.length === 0 && this.draggedDocuments.length === 0) || this.dragged.includes('f' + folder.id)) {
        this.dragged = []
        this.draggable = null
        // this.draggedFolders = []
        this.draggedDocuments = []
        this.updateCheckboxes()

        return
      }

      const foldersData = []

      for (let i = 0; i < this.draggedFolders.length; i++) {
        const currentFolder = this.draggedFolders[i]

        if (currentFolder.id !== folder.id) {
          foldersData.push({
            id: currentFolder.id,
            parent: {
              id: folder.id
            }
          })
        }
      }

      if (foldersData.length) {
        const foldersFormData = new FormData()
        foldersFormData.append('data', JSON.stringify(foldersData))

        Vue.prototype.$http
          .post(config.apiUrl + '/folders', foldersFormData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: 'PUT'
            }
          })
          .then(response => {
            if (response.status === 200) {
              const folders = response.data

              if (folders) {
                this.$store.commit('deleteFolders', folders)
                this.successMessage = this.$t('message.selection_moved')
                this.errors = []
              }
            }
          })
          .catch(e => {
            const response = e.response

            if (!response) {
              return
            }

            if (response.status === 400) {
              const data = response.data
              this.errors = data.violations
              this.successMessage = null
            }

            if (response.status === 500) {
              this.errors.push({
                title: this.$t('message.error_moving_selection')
              })
            }
          })
      }

      const documentsData = []

      for (let i = 0; i < this.draggedDocuments.length; i++) {
        const currentDocument = this.draggedDocuments[i]

        documentsData.push({
          id: currentDocument.id,
          folder: folder.id,
          vault: this.$store.getters.getCurrentVault.id
        })
      }

      if (documentsData.length) {
        const documentsFormData = new FormData()
        documentsFormData.append('data', JSON.stringify(documentsData))

        Vue.prototype.$http
          .post(config.apiUrl + '/documents', documentsFormData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: 'PUT'
            }
          })
          .then(response => {
            if (response.status === 200) {
              const documents = response.data

              if (documents) {
                this.$store.commit('deleteDocuments', documents)
                this.successMessage = this.$t('message.selection_moved')
                this.errors = []

                if (this.documents.length === 0) {
                  this.nextPage = this.nextPage - 1

                  this.getDocuments(this.mainFolder)
                }
              }
            }
          })
          .catch(e => {
            const response = e.response

            if (!response) {
              return
            }

            if (response.status === 400) {
              const data = response.data
              this.errors = data.violations
              this.successMessage = null
            }

            if (response.status === 500) {
              this.errors.push({
                title: this.$t('message.error_moving_selection')
              })
            }
          })
      }

      this.dragged = []
      this.draggable = null
      this.draggedFolders = []
      this.draggedDocuments = []
      this.checkbox = false
      this.updateCheckboxes()
    },
    onMouseUpDocument (event, document) {
      if (!this.hasEditableContent) {
        return
      }

      this.clickCount += 1
      clearTimeout(this.timer)
      setTimeout(() => { this.clickCount = 0 }, 500)

      this.onMouseUpOverlay()
    },
    onMouseOver (event, folder) {
      if (!this.hasEditableContent) {
        return
      }

      if (this.dragged.length && !this.dragged.includes('f' + folder.id)) {
        this.draggable = folder.id
      }
    },
    getFolder () {
      if (!this.currentRootFolder) {
        return
      }

      const queryFolder = this.$route.query.folder
      const folder = queryFolder || this.currentRootFolder.id

      this.loading = true

      return Vue.prototype.$http
        .get(config.apiUrl + '/folders/' + folder, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            page: this.nextPage
          }
        })
        .then(response => {
          if (response.status === 200) {
            const folder = response.data

            this.mainFolder = folder

            if (folder) {
              this.breadCrumb = folder.breadCrumb

              const children = folder.children.filter(element => element.deleted === false)

              if (children && children.length) {
                this.$store.commit('setFolders', children)
              }

              this.getDocuments(folder)
            }
          }
        })
        .catch(e => {
          if (Vue.prototype.$http.isCancel(e)) {
            console.log('Request canceled', e.message)
          } else {
            console.log(e)
          }

          const response = e.response

          if (response.status === 500) {
            this.mkError = this.$t('message.error_retrieving_items')
          }

          this.loading = false
        })
    },
    getDocuments (folder) {
      if (!folder) {
        return
      }

      this.$store.commit('setHandlingDocumentScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            folder: folder.id,
            page: this.nextPage
          }
        })
        .then(response => {
          if (response.status === 200) {
            const documents = response.data

            if (documents && documents.length) {
              this.$store.commit('setDocuments', documents)

              this.nextPage = this.nextPage + 1
            }

            this.xTarget = response.headers['x-target']
          }

          this.loading = false
          this.$store.commit('setHandlingDocumentScroll', false)

          this.resolveNewAccountMessage()
        })
        .catch(e => {
          this.$store.commit('setDocuments', [])
          this.$store.commit('setHandlingDocumentScroll', false)

          const response = e.response

          if (response.status === 404) {
            this.mkError = this.$t('message.service_maintenance')
          }

          if (response.status === 500) {
            this.mkError = this.$t('message.error_fetching_items')
          }

          this.loading = false
        })
    },
    resolveNewAccountMessage () {
      this.showNewAccountMessage = (this.user &&
        this.user.rcu &&
        this.documents.length === 0 &&
        this.folders.length === 0 &&
        this.isNewAccount() &&
        this.currentRootFolder.name === 'fiducial_documents')
    },
    chooseFiles (event) {
      const files = event.target.files

      if (files.length > 0) {
        this.$store.commit('setDocumentsInProgressState', true)

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const currentUploadId = this.generateRandomString()
          const currentUpload = {
            uploadId: currentUploadId,
            file: file,
            document: {
              uploadId: currentUploadId,
              name: file.name,
              status: null,
              progress: 0,
              progressCircle: null,
              errors: null
            }
          }

          this.uploadQueue.push(currentUpload)

          this.$store.commit('addDocumentInProgress', currentUpload.document)
        }

        if (this.uploadAvailable) {
          this.handleUpload(this.uploadQueue[0])
        }
      }
    },
    generateUniqueId () {
      const dateString = Date.now().toString(36)
      const randomness = Math.random().toString(36).substr(2)
      const moreRandomness = Math.floor(Math.random() * 123456)

      return randomness + dateString + moreRandomness
    },
    generateRandomString(length = 32) {
      return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('')
    },
    setFile (file) {
      this.file = file
      this.size = 5000000
      this.setTotalNumber()
    },
    setTotalNumber () {
      const total = Math.ceil(this.file.size / this.size)

      this.totalNumber = total > 0 ? total : 1
    },
    getNumber () {
      return (this.start / this.size) + 1
    },
    slice (start, end) {
      return this.file.slice(start, end - 1)
    },
    handleUpload (uploadData) {
      if (!this.hasEditableContent) {
        return
      }

      if (!this.currentRootFolder) {
        return
      }

      this.totalNumber = 0
      this.start = 0
      this.size = 0
      this.file = {}
      this.uploadAvailable = false
      this.uploadQueue = this.uploadQueue.filter(element => element.uploadId !== uploadData.uploadId)

      const allowedExtraExtensions = ['.pak', '.log', '.dmg', '.pkg', '.sql', '.app', '.exe', '.conf', '.7z', '.gz', '.zip', '.rar', '.plist']

      let hasAllowedExtraExtension = false

      for (let i = 0; i < allowedExtraExtensions.length; i++) {
        const currentExtraExtension = allowedExtraExtensions[i]

        if (uploadData.file.name.includes(currentExtraExtension)) {
          hasAllowedExtraExtension = true
        }
      }

      if (uploadData.file.type.length === 0 && !hasAllowedExtraExtension) {
        uploadData.document.status = 'error'
        uploadData.document.progress = 0
        uploadData.document.errors = [
          { title: this.$t('message.document_upload_error') }
        ]

        if (this.uploadQueue.length) {
          this.handleUpload(this.uploadQueue[0])
        } else {
          this.uploadAvailable = true
        }

        return
      }

      if (encodeURIComponent(uploadData.file.name).length > 215) {
        uploadData.document.status = 'error'
        uploadData.document.progress = 0
        uploadData.document.errors = [
          { title: this.$t('message.document_upload_filename_too_big_error') }
        ]

        if (this.uploadQueue.length) {
          this.handleUpload(this.uploadQueue[0])
        } else {
          this.uploadAvailable = true
        }

        return
      }

      if (uploadData.file.size > 100000000) {
        uploadData.document.status = 'error'
        uploadData.document.progress = 100
        uploadData.document.errors = [
          { title: this.$t('message.document_size_limit_exceeded') }
        ]

        if (this.uploadQueue.length) {
          this.handleUpload(this.uploadQueue[0])
        } else {
          this.uploadAvailable = true
        }

        return
      }

      this.pushDocument(uploadData)
    },
    pushDocument (uploadData) {
      this.setFile(uploadData.file)

      this.$store.commit('setDocumentsInProgressState', true)

      const queryFolder = this.$route.query.folder
      const folder = queryFolder || this.currentRootFolder.id

      const data = new FormData()
      data.append('document', this.slice(this.start, (this.start + this.size) + 1))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents', data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'X-Target': this.xTarget,
            'x-chunk-number': this.getNumber(),
            'x-chunk-total-number': this.totalNumber,
            'x-chunk-size': 5000000,
            'x-file-name': encodeURIComponent(uploadData.file.name),
            'x-file-size': uploadData.file.size,
            'x-file-identity': uploadData.document.uploadId,
            'x-file-vault': this.$store.getters.getCurrentVault.id,
            'x-file-folder': folder
          }
        })
        .then(response => {
          this.start += this.size

          switch (response.status) {
            case 200:
              if (uploadData.document.uploadId) {
                if (!uploadData.document.progressCircle) {
                  uploadData.document.progressCircle = this.generateProgressCircle('.p' + uploadData.document.uploadId)
                }

                uploadData.document.progress = Math.round(100)

                uploadData.document.progressCircle.animate(uploadData.document.progress / 100)
              }

              if (this.uploadQueue.length) {
                this.handleUpload(this.uploadQueue[0])
              } else {
                this.uploadAvailable = true
              }

              break
            case 201:
              if (uploadData.document.uploadId) {
                if (!uploadData.document.progressCircle) {
                  uploadData.document.progressCircle = this.generateProgressCircle('.p' + uploadData.document.uploadId)
                }

                uploadData.document.progress = Math.round(response.data.progress)

                uploadData.document.progressCircle.animate(uploadData.document.progress / 100)
              }

              if (this.start < this.file.size) {
                this.pushDocument(uploadData)
              }

              break
          }
        })
        .catch(error => {
          if (error.response) {
            if (this.codes.includes(error.response.status)) {
              uploadData.document.status = 'error'
              uploadData.document.progress = 0
              uploadData.document.errors = [
                { title: this.$t('message.document_upload_error') }
              ]

              if (this.uploadQueue.length) {
                this.handleUpload(this.uploadQueue[0])
              } else {
                this.uploadAvailable = true
              }
            } else if (error.response.status === 403) {
              uploadData.document.status = 'error'
              uploadData.document.progress = 0
              uploadData.document.errors = [
                { title: this.$t('message.document_non_compliant') }
              ]
            } else if (error.response.status === 422) {
              console.warn('Validation Error', error.response.data)
            } else {
              console.log('Re-uploading the chunk...')
              this.pushDocument()
            }
          } else {
            uploadData.document.status = 'error'
            uploadData.document.progress = 0
            uploadData.document.errors = [
              { title: this.$t('message.document_upload_error') }
            ]

            if (this.uploadQueue.length) {
              this.handleUpload(this.uploadQueue[0])
            } else {
              this.uploadAvailable = true
            }
          }
        })
    },
    putDocument (document) {
      const data = {
        id: document.id,
        read: true,
        vault: this.$store.getters.getCurrentVault.id
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents/' + document.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            document.read = true
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400) {
          }

          if (response.status === 500) {
          }
        })
    },
    generateProgressCircle (container) {
      const progressCircle = new ProgressBar.Circle(container, {
        color: '#95abec',
        strokeWidth: 8,
        trailWidth: 8,
        easing: 'easeInOut',
        duration: 1000,
        text: {
          autoStyleContainer: false
        },
        from: { color: '#4e73df', width: 8 },
        to: { color: '#4e73df', width: 8 },
        step: (state, circle) => {
          circle.path.setAttribute('stroke', state.color)
          circle.path.setAttribute('stroke-width', state.width)

          const value = Math.round(circle.value() * 100)

          circle.setText(value === 0 ? '' : value)
        }
      })

      progressCircle.text.style.fontFamily = '"Raleway", Helvetica, sans-serif'
      progressCircle.text.style.fontSize = '.7rem'

      return progressCircle
    },
    openFolder (folder) {
      if (folder) {
        const rootFolderSlug = slugify(this.currentRootFolder.name, { lower: true })

        this.$router.push({ name: 'DocumentList', params: { rootFolderName: rootFolderSlug }, query: { folder: folder.id } }).catch(err => err)
      }
    },
    showName (element) {
      return element.name
    },
    downloadFolder (folder) {
      if (!folder || !this.currentRootFolder) {
        return
      }

      const queryFolder = this.$route.query.folder
      const parentFolder = queryFolder || this.currentRootFolder.id

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            format: 'zip',
            folder: parentFolder,
            tree: folder.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, folder.name)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = decodeURIComponent(escape(headers['content-name']))
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)

            if (document.read !== true) {
              this.putDocument(document)
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadSelected () {
      if (!this.currentRootFolder) {
        return
      }

      const queryFolder = this.$route.query.folder
      const parentFolder = queryFolder || this.currentRootFolder.id

      const documentIds = this.documents
        .filter(element => element.selected === true)
        .map(element => element.id)

      const folderIds = this.folders
        .filter(element => element.selected === true)
        .map(element => element.id)

      const params = {
        vault: this.$store.getters.getCurrentVault.id,
        format: 'zip',
        folder: parentFolder
      }

      if (documentIds.length === 0 && folderIds.length === 0) {
        this.emptySelectionDialogState = true

        return
      }

      if (documentIds.length) {
        params.ids = documentIds.join(',')
      }

      if (folderIds.length) {
        params.tree = folderIds.join(',')
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params,
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, 'documents.zip')

            this.checkbox = false
            this.updateCheckboxes()
            this.checkboxCount = 0
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    resolveIcon (document) {
      if (this.isPdf(document)) {
        return 'mdi-file-pdf-outline'
      }

      if (this.isImg(document)) {
        return 'mdi-file-image-outline'
      }

      if (this.isXls(document)) {
        return 'mdi-file-excel-outline'
      }

      if (this.isCsv(document)) {
        return 'mdi-file-table-outline'
      }

      if (this.isTxt(document)) {
        return 'mdi-file-document-outline'
      }

      const word = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

      if (word.includes(document.mimeType)) {
        return 'mdi-file-word-outline'
      }

      const powerpoint = ['application/vnd.oasis.opendocument.presentation', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']

      if (powerpoint.includes(document.mimeType)) {
        return 'mdi-file-powerpoint-outline'
      }

      const audio = ['audio/aac', 'audio/midi', 'audio/x-midi', 'audio/mp3', 'audio/oga', 'audio/wav', 'audio/weba']

      if (audio.includes(document.mimeType)) {
        return 'mdi-file-music-outline'
      }

      const video = ['video/x-msvideo', 'video/mpeg', 'video/ogv', 'video/mp2t', 'application/vnd.visio', 'video/webm']

      if (video.includes(document.mimeType)) {
        return 'mdi-file-video-outline'
      }

      const archive = ['application/gzip', 'application/x-freearc', 'application/zip', 'application/rar', 'application/x-bzip', 'application/x-bzip2', 'application/java-archive', 'application/x-tar', 'application/x-7z-compressed']

      if (archive.includes(document.mimeType)) {
        return 'mdi-archive-outline'
      }

      return 'mdi-file-document-outline'
    },
    isPreviewable (document) {
      if (this.isPdf(document)) {
        return true
      }

      if (this.isImg(document)) {
        return true
      }

      if (this.isCsv(document)) {
        return true
      }

      if (this.isTxt(document)) {
        return true
      }
    },
    updateCheckboxes () {
      this.checkboxCount = 0

      for (let i = 0; i < this.folders.length; i++) {
        const currentFolder = this.folders[i]
        currentFolder.selected = this.checkbox
        this.updateCheckboxCount(currentFolder.selected)
      }

      for (let i = 0; i < this.documents.length; i++) {
        const currentDocument = this.documents[i]
        currentDocument.selected = this.checkbox
        this.updateCheckboxCount(currentDocument.selected)
      }
    },
    updateCheckboxCount (value) {
      value === true ? this.checkboxCount++ : (this.checkboxCount === 0 ? this.checkboxCount = 0 : this.checkboxCount--)
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    isNewAccount () {
      const timestamp = Date.parse(this.user.createdAt)
      const date1 = new Date(timestamp)
      const date2 = new Date()

      const diff = Math.abs(date2 - date1)
      const diffInDays = diff / (1000 * 60 * 60 * 48)

      return diffInDays < 1
    },
    resolveCurrentUsage () {
      if (this.vaultDiskUsage) {
        const currentUsage = parseInt(this.vaultDiskUsage.currentUsage)

        return currentUsage === 0 ? currentUsage : (currentUsage / 1000000).toFixed(2)
      }

      return 0
    },
    resolvePercentUsage () {
      if (this.vaultDiskUsage) {
        const currentUsage = parseInt(this.vaultDiskUsage.currentUsage)
        const maxUsage = parseInt(this.vaultDiskUsage.maxUsage)

        return (currentUsage * 100 / maxUsage).toFixed(2)
      }

      return 0
    },
    ignoreUsageMsg () {
      this.$store.commit('setIgnoredUsageMsg', true)
    },
    getUpgradeStorageUrl () {
      return config.upgradeOptionsUrl
    },
    showDocumentDetails (doc) {
      this.currentDocument = doc
      this.documentDetailsLoader = true
      this.documentDetailsStatus = true
      this.documentHistoryStatus = false
      this.documentHistoryLoader = false

      document.getElementsByClassName('header-details')[0].classList.add('active')
      document.getElementsByClassName('header-history')[0].classList.remove('active')
      document.getElementById('document-details').classList.add('openDocumentDetails')
      document.getElementById('sub-wrapper').classList.add('openDocumentDetails')

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + this.currentDocument.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            details: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.checkDocumentIntegrity(this.currentDocument)
          }
        })
        .catch(e => {
          this.documentDetailsLoader = false
        })
    },
    showDocumentHistory (doc) {
      this.currentDocument = doc
      this.documentDetailsStatus = false
      this.documentDetailsLoader = false
      this.documentHistoryStatus = true
      this.documentHistoryLoader = true

      document.getElementsByClassName('header-details')[0].classList.remove('active')
      document.getElementsByClassName('header-history')[0].classList.add('active')
      document.getElementById('document-details').classList.add('openDocumentDetails')
      document.getElementById('sub-wrapper').classList.add('openDocumentDetails')

      Vue.prototype.$http
        .get(config.apiUrl + '/logs', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            user: this.$store.getters.getUser.id,
            documentId: this.currentDocument.id
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.currentLogs = response.data
          }

          this.documentHistoryLoader = false
        })
        .catch(e => {
          this.documentHistoryLoader = false
        })
    },
    hideDocumentDetails () {
      document.getElementById('document-details').classList.remove('openDocumentDetails')
      document.getElementById('sub-wrapper').classList.remove('openDocumentDetails')
      this.documentDetailsStatus = false
      this.currentDocument = null
    },
    checkDocumentIntegrity (doc) {
      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + this.currentDocument.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            check: true
          }
        })
        .then(response => {
          this.isCurrentDocumentIntact = response.status === 200
          this.documentDetailsLoader = false
        })
        .catch(e => {
          this.isCurrentDocumentIntact = false
          this.documentDetailsLoader = false
        })
    },
    openCreateFolderDialog () {
      if (!this.hasEditableContent) {
        return
      }

      this.createFolderDialogState = true
    },
    closeCreateFolderDialog () {
      this.createFolderDialogState = false
    },
    openUpdateFolderDialog (folder) {
      if (!this.hasEditableContent) {
        return
      }

      this.updateFolderDialogState = true
      this.folderToUpdate = folder
    },
    closeUpdateFolderDialog () {
      this.updateFolderDialogState = false
    },
    openDeleteFolderDialog (folder) {
      if (!this.hasDeletableContent) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/folders/' + folder.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'document'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.deleteFolderDialogState = true
            this.folderToDelete = response.data
          }

          this.loading = false
        })
        .catch(e => {
        })
    },
    closeDeleteFolderDialog () {
      this.deleteFolderDialogState = false
    },
    openUpdateDocumentDialog (document) {
      if (!this.hasEditableContent) {
        return
      }

      this.updateDocumentDialogState = true
      this.documentToUpdate = document
    },
    closeUpdateDocumentDialog () {
      this.updateDocumentDialogState = false
    },
    openDeleteDocumentDialog (document) {
      if (!this.hasDeletableContent) {
        return
      }

      this.deleteDocumentDialogState = true
      this.documentToDelete = document
    },
    closeDeleteDocumentDialog () {
      this.deleteDocumentDialogState = false

      if (this.documents.length === 0) {
        this.nextPage = this.nextPage - 1

        this.getDocuments(this.mainFolder)
      }
    },
    openPreviewDocumentDialog (document) {
      if (this.isPdf(document)) {
        this.previewPdfDialogState = true
        this.destroyPreviewPdfComponent = true
      } else if (this.isImg(document)) {
        this.previewImgDialogState = true
        this.destroyPreviewImgComponent = true
      } else if (this.isCsv(document)) {
        this.previewCsvDialogState = true
        this.destroyPreviewCsvComponent = true
      } else if (this.isTxt(document)) {
        this.previewTxtDialogState = true
        this.destroyPreviewTxtComponent = true
      }

      this.documentToPreview = document
    },
    closePreviewPdfDialog () {
      this.previewPdfDialogState = false
      this.destroyPreviewPdfComponent = false
    },
    closePreviewImgDialog () {
      this.previewImgDialogState = false
      this.destroyPreviewImgComponent = false
    },
    closePreviewXlsDialog () {
      this.previewXlsDialogState = false
      this.destroyPreviewXlsComponent = false
    },
    closePreviewCsvDialog () {
      this.previewCsvDialogState = false
      this.destroyPreviewCsvComponent = false
    },
    closePreviewTxtDialog () {
      this.previewTxtDialogState = false
      this.destroyPreviewTxtComponent = false
    },
    openDeleteSelectedDialog () {
      if (!this.hasDeletableContent) {
        return
      }

      this.loading = true

      const documentIds = this.documents
        .filter(element => element.selected === true)
        .map(element => element.id)

      const folderIds = this.folders
        .filter(element => element.selected === true)
        .map(element => element.id)

      this.documentIds = documentIds
      this.documentsToDelete = this.documents.filter(element => element.selected === true)
      this.folderIds = folderIds

      if (this.folderIds.length === 0) {
        this.foldersToDelete = []

        this.loading = false

        if (documentIds.length > 0 || folderIds.length > 0) {
          this.deleteSelectedDialogState = true
        } else {
          this.checkboxCount = 0
          // this.emptySelectionDialogState = true
        }

        return
      }

      const data = {
        ids: folderIds.join(',')
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/folders-infos', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            nbChildren: true,
            source: 'document'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.foldersToDelete = response.data
          }

          if (documentIds.length > 0 || folderIds.length > 0) {
            this.deleteSelectedDialogState = true
          } else {
            this.checkboxCount = 0
            // this.emptySelectionDialogState = true
          }

          this.loading = false
        })
        .catch(e => {
        })
    },
    closeDeleteSelectedDialog () {
      this.deleteSelectedDialogState = false
      this.checkbox = false
      this.updateCheckboxes()

      if (this.documents.length === 0) {
        this.nextPage = this.nextPage - 1

        this.getDocuments(this.mainFolder)
      }
    },
    closeEmptySelectionDialog () {
      this.emptySelectionDialogState = false
      this.checkbox = false
      this.updateCheckboxes()
    },
    isPdf (document) {
      const pdf = ['application/pdf']

      return pdf.includes(document.mimeType)
    },
    isImg (document) {
      const image = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/gif', 'image/bmp', 'image/vnd.microsoft.icon', 'image/tiff', 'image/webp']

      return image.includes(document.mimeType)
    },
    isXls (document) {
      const excel = ['application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

      return excel.includes(document.mimeType)
    },
    isCsv (document) {
      const table = ['text/csv']

      return table.includes(document.mimeType)
    },
    isTxt (document) {
      const txt = ['text/plain']

      return txt.includes(document.mimeType)
    },
    truncate (document) {
      const name = document.name + '.' + document.extension

      if (name.length > 20) {
        return name.substring(0, 20) + '...'
      }

      return name
    },
    resolveSize (size) {
      const sizes = [
        { name: 'Bytes', round: 0 },
        { name: 'Ko', round: 0 },
        { name: 'Mo', round: 2 },
        { name: 'Go', round: 2 },
        { name: 'To', round: 2 }
      ]

      if (size === 0) {
        return '0 Byte'
      }

      const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)))

      return (size / Math.pow(1024, i)).toFixed(sizes[i].round) + ' ' + sizes[i].name
    }
  },
  watch: {
    clickCount () {
      if (this.clickCount === 2) {
        this.clickCount = 0
      }
    }
  },
  components: {
    CreateFolderDialog,
    UpdateFolderDialog,
    DeleteFolderDialog,
    UpdateDocumentDialog,
    DeleteDocumentDialog,
    PreviewPdfDialog,
    PreviewImgDialog,
    PreviewXlsDialog,
    PreviewCsvDialog,
    PreviewTxtDialog,
    DeleteSelectedDialog,
    EmptySelectionDialog
  }
}
</script>
